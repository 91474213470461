<template>
  <div class="error error__wrapper">
    <div class="error__content">
      <div class="error__items">
        <h1 class="error__items-title">404</h1>
        <h3 class="error__items-text title-h4">
          {{ $t(`error.page_not_found`) }}
        </h3>
        <Button class="error__items-button" @click.native="$router.push('/')">
          {{ $t('buttons.back_to_home') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.error__wrapper
  background: linear-gradient(282deg, #FCFBF2 -2.13%, #F9F7E5 20.56%, #E4F0FF 82.05%)
  height: 100vh

.error__content
  background: url('/images/main_bg.png') 100% 100% no-repeat
  display: flex
  justify-content: space-between
  align-items: center
  height: 100%
  margin-left: 65px
  @include m
    display: flex
    flex-direction: column
    margin-left: 0
    background-size: 70vh

.error__items
  @include m
    margin-top: 5rem
    text-align: center

.error__items-title
  font-size: 280px
  line-height: normal
  font-weight: 200
  @include xs
    font-size: 200px
  @include xxs
    font-size: 140px
    margin-bottom: 4rem

.error__items-text
  line-height: 40px
  max-width: 585px
  margin-bottom: 70px
  @include m
    text-align: center
  @include xxs
    line-height: 28px

.error__items-button
  min-width: 300px
  @include m
    margin: auto

.footer
  display: none
</style>
